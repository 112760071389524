<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="type"> 新增站点 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain v-if="$hasAccess('device.group/add')">保存 </el-button>
				<el-button @click="close" size="small" icon="el-icon-close" plain>取消 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<el-col :xs="24" :sm="10">
						<!-- 小区信息 -->
						<div class="el-block">
							<div class="row-title">占位信息</div>
							<el-form-item label="站点名称" prop="group_id">
								<el-select
										v-model="formData.group_id"
										size="small"
										style="width: 100%"
										filterable
										remote
										reserve-keyword
										clearable
										placeholder="请选择小区"
										:remote-method="remoteGroup"
										:loading="groupLoading">
									<el-option
											v-for="item in groupList"
											:key="item.group_id"
											:label="item.group_name"
											:value="item.group_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="免费时长" prop="free_duration">
								<el-input-number  :precision="0" :min="0" :step="1" v-model="formData.free_duration" clearable />分钟
							</el-form-item>
							<div class="agent-choose-form">
								<div class="title">
									 <span class="tips">计费区间</span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="onDiscountItemAdd">增加</el-button>
								</div>
								<div v-for="idx in groupDiscountList.length" class="agent-item">
									<el-input v-model="groupDiscountList[idx-1].time_slot" style="width: 100px;margin-right: 10px" clearable />
									<el-input v-model="groupDiscountList[idx-1].time_slot_2" style="width: 100px;" clearable />
									<el-input-number type="number" :min="0" :max="10" :step="0.01" :precision="4" v-model="groupDiscountList[idx-1].unit_price" class="input" size="small"
													 placeholder="请输入单价"></el-input-number>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onDiscountItemDel(idx)">删除</el-button>
								</div>
							</div>
							<el-form-item label="最大收费" prop="max_money">
								<el-input-number  :precision="0" :min="0" :step="1" v-model="formData.max_money" clearable />元
							</el-form-item>
							<el-form-item label="生效时间" prop="select_time">
								<el-date-picker class="from-item-width" style="width: 45%" unlink-panels v-model="formData.select_time" type="daterange" clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
							</el-form-item>
						</div>

					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	import API from '@/service/api'
	import {
		mapMutations
	} from 'vuex'
	import BMap from '@/components/b-map'
	import upload from '@/components/upload'
	import {
		bd2gd,
		gd2bd
	} from '../../../utils/util'
	export default {
		name: 'device-seat-add',
		components: {
		},
		data() {
			return {
				// 表单信息
				formData: {

				},
				selecttime: {},
				selecttimeedit: {},
				// 代理商分成数组
				groupDiscountList: [{
					time_slot: 0,
					time_slot_2: 24,
					unit_price: 0
				}],
				// 特殊字段-合作规则
				cooperate_rule: {
					order: 0,
					recharge: 0,
					buy: 0
				},
				// 特殊字段-充值优惠
				plan_id: '',
				label_name:'',
				// 选项列表
				feeList: [],
				agentList: [],
				planList: [],
				groupList: [],
				groupLoading:false,
				giftList: [],
				// 规则
				rules: {
					group_id: [{
						required: true,
						message: '请选择站点',
						trigger: 'change'
					}],
					free_duration: [{
						required: true,
						message: '请输入免费时长',
						trigger: 'change'
					}],
					select_time: [{
						required: true,
						message: '请选择生效时间',
						trigger: 'change'
					}]
				},
				showMap: false,
				completedDate: '',
				// 设备类型
				label: [],
			}
		},
		mounted() {
			this.init()
		},
		watch: {
			"cooperate_rule.recharge"() {
				this.cooperate_rule.order = this.cooperate_rule.recharge;
			},
			"cooperate_rule.order"() {
				this.cooperate_rule.recharge = this.cooperate_rule.order;
			},
		},
		async activated() {
			this.init()
		},
		methods: {
			...mapMutations(['setTabList']),
			// 初始化请求
			init() {
				this.remoteGroup();
			},
			// 小区
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 保存
			save(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.formData.start_time = parseInt(this.formData.select_time[0].getTime() / 1000);
						this.formData.end_time = parseInt(this.formData.select_time[1].getTime() / 1000);
						this.formData.group_data = JSON.stringify(this.groupDiscountList);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						await API.Device.AddSeat(params)
						this.$notify({
							title: '成功',
							message: '新建成功',
							type: 'success'
						})
						this.closeTab()
					}
				})
			},
			// 关闭
			close() {
				this.$confirm('确定要关闭当前页面吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.closeTab()
				})
			},
			// 关闭tab
			closeTab() {
				let tabs = this.$store.state.app.tabList
				this.setTabList(tabs.filter((tab) => tab.name !== 'device-seat-add'))
				this.$router.push({
					name: 'device-seat-list'
				})
			},
			onAgentItemAdd: function () {
				this.cooperateAgentList.push({
					agent_id: null,
					ratio: null
				});
			},
			onAgentItemDel: function (idx) {
				this.cooperateAgentList.splice(idx - 1, 1);
			},
			onDiscountItemAdd: function () {
				this.groupDiscountList.push({
					time_slot: null,
					time_slot_2:null,
					unit_price: 0
				});
			},
			onDiscountItemDel: function (idx) {
				this.groupDiscountList.splice(idx - 1, 1);
			}
		}
	}
</script>
<style lang="scss">
	.toolbar {
		display: flex;
		justify-content: space-between;
		position: relative;
		min-height: 40px;

		.type {
			font-size: 16px;
			display: flex;
			align-items: center;
		}

		.action-box {
			line-height: 40px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			z-index: 9;
		}
	}

	.agent-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.agent-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 250px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.agent-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
